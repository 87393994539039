import axios from 'axios'
import date from 'date-and-time';
// import { prepareOrderRowHelper } from '../data/helpers/prepareOrderRowHelper'
// import { getAreaListApi } from './areaListApi'
// import { getCityListApi } from './cityListApi'
import { getManagerListApi } from './managerListApi'
// import orders from './importOrdersUA.json'
import db from './../firebase/firebase';
// import promocodesList from '../api/promocodesList.json'
import cityListData from '../old/cityListData.json'
import { BACKEND_DOMAIN } from './../data/constants/statuses';

export const addOrderApi = async (userData, managerList) => {

    const { token } = userData

    const resOrders = await getMonthOrderListApi(2021, 7, managerList)
    // const resOrders = await getOrderListShopApi()
    // console.log("🚀 ~ file: orderListApi.js ~ line 69 ~ addOrderApi ~ resOrders", resOrders)

    // Object.values(resOrders).forEach((item, index) => {


    //     axios
    //         .post('http://localhost:133/orders', {...item, locale: "uk-UA"},
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${token}`
    //                 }
    //             }).then(response => {
    //                 console.log(response);
    //             });
    // })
}

export const getMonthOrderListApi = async (userData, year, month) => {
    const { token, managerId, managerLocale } = userData
    // const noPromoForUA = managerLocale === "uk-UA" ? `promocode_ncontains=no orderPromocode` : ''
    const res = axios
        .get(`${BACKEND_DOMAIN}/orders?_locale=${managerLocale}&manager=${managerId}&_limit=-1&orderYear=${year}&orderMonth=${month}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                return response.data
            });
    return res;
}

export const getAdminMonthOrderListApi = async (userData, year, month, location) => {
    const { token } = userData
    const res = axios
        .get(`${BACKEND_DOMAIN}/orders?_locale=${location}&_limit=-1&orderYear=${year}&orderMonth=${month}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(response => {
                return response.data
            });
    return res;
}

// temporarily for upload cities
// export const addCitiesApi = async (userData) => {

//     const {token} = userData

//     Object.entries(cityListData).forEach((item, index) => {
// const city = item[0]
// const region = item[1]

//         axios
//             .post('http://localhost:1337/cities', {city: city, region: region, locale: "uk-UA"},
//                 {
//                     headers: {
//                         Authorization: `Bearer ${token}`
//                     }
//                 }).then(response => {
//                     console.log(response);
//                 });
//     })
// }